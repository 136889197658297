
import { Link } from "react-router-dom";
import "./ThankYou.css";

const ThankYou = () => {
   


    return (
        <>
            <div className="thank-you-bg">
                <div className="container pt-3">
                    <h1 className="text-center mt-5">Thank You!</h1>
                    <div className="text-center my-5">
                        <img src={require("../images/homeImages/thankyou.png")} className="img-fluid col-2" />
                    </div>
                    <h3 className="text-center">
                     We appreciate your interest in <span style={{ color: "#028D4A" }}>Datart Infotech.</span> <br />
                        A member of our team will be in touch with you shortly.
                    </h3>
                    <h5 className="my-4 text-center">Learn more about our expertise by browsing through our highlighted projects.</h5>

                   <div className="text-center mt-5">
                   <Link to="/#projects">
                            <button style={{ backgroundColor: "rgb(34, 147, 187)" }} className="text-decoration-none p-2 px-5 text-white rounded">Explore Our Projects</button>
                        </Link>
                   </div>

                </div>
            </div>
        </>
    );
}

export default ThankYou;
