export const CustomerItems = [
    {
      img: require("../../images/ss.png"),
      subTitle: "Erosion Monitoring WebApp",
      title: "SS Services",
      para: [
        {
          p: "The project aimed to seamlessly create custom IoT devices which were integrated with our developed erosion monitoring software tailored specifically for the thermal power plant infrastructure. This helps in planning and maintenance of their data to make power plants work better, with our environment friendly built device.",
        },
        {
          p: "",
        },
      ],
      techs:
        "Figma, HTML, CSS, React JS, Node JS, MongoDB, Google cloud platform",
    },
    {
      img: require("../../images/ize.png"),
      subTitle: "Print on demand e-commerce",
      title: "IZE",
      para: [
        {
          p: "IZE, a Print-on-Demand E-commerce platform empowering artists and designers to showcase their creations and merchandise in a lifelike 3D environment. The platform caters to both artists and customers, offering them distinct features to navigate the site efficiently.",
        },
        {
          p: "",
        },
      ],
      techs:
        "Figma, HTML, CSS, React JS, Node JS, MongoDB, Google cloud platform",
    },
    {
      img: require("../../images/24.png"),
      subTitle: "Educational Service provider",
      title: "Path2study",
      para: [
        {
          p: "The primary goal of the Path2Study is to provide scholarship and student loan information in an intuitive, user-friendly  way that caters to the needs of students seeking financial assistance for their education.The project aims to address the challenges students face when navigating scholarship and loan options, providing them with a comprehensive resource to make well-informed decisions.",
        },
  
       
      ],
      techs:
        "Figma, HTML, CSS, React JS, Node JS, MongoDB, Google cloud platform",
    },
    {
      img: require("../../images/3d.png"),
      subTitle: "3D Printing and Designing",
      title: "The Additive Minds",
      para: [
        {
          p: "The Additive Minds is a 3D printing and manufacturing service provider specialized in precision and quality. They are specialized in providing services in commercial sector of the industry. The brand integrates advanced technologies and innovative processes to deliver high-quality prototypes, products, and components with precision and efficiency.",
        },
  
        
      ],
      techs:
        "Figma, HTML, CSS, React JS, Node JS, MongoDB, Google cloud platform",
    },
  
    {
      img: require("../../images/kaash.png"),
      subTitle: "Kaash Studio",
      title: "Kaash Studio",
      para: [
        {
          p: "Kaash Studio is a 3D printing hub that offers bespoke solutions marked by unparalleled quality and unwavering commitment to two dynamic industries. They combine expertise, innovation, and state-of-the-art technology to bring your ideas to life in stunning detail offering two distinct services in  interior and commercial sector. ",
        },
        {
          p: "",
        },
      ],
      techs:
        "Figma, HTML, CSS, React JS, Node JS, MongoDB, Google cloud platform",
    },
    {
      img: require("../../images/46.png"),
      subTitle: "Fintech Webapp",
      title: "Finexa Capital",
      para: [
        {
          p: "Finexa Capital aimed to streamline financial services, foster closer client relationships, and display their comprehensive loan portfolio, wide range of SME services with a one-stop solution for diverse customer needs.",
        },
        {
          p: "",
        },
      ],
      techs: "Figma, HTML, CSS, React JS",
    },
    {
      img: require("../../images/success2.png"),
      subTitle: "Interior Design and Architecture",
      title: "Chhavi Studio",
      para: [
        {
          p: "Studio Chhavi sought a way to streamline their design processes, enhance client collaboration, and present their portfolio in an engaging manner through intuitive user interfaces.",
        },
        {
          p: "A digital solution that aligned with their vision of elegance and innovation.",
        },
      ],
      techs: "Figma, HTML, CSS, React JS",
    },
    {
      img: require("../../images/success1.png"),
      subTitle: "E-commerce ",
      title: "Sanjoli Sarees",
      para: [
        {
          p: "SanjoliSarees.com is an E-commerce platform providing customers with an interface to purchase desired sarees among the listed products.",
        },
        {
          p: "It is one of the leading saree brands in Gujarat.",
        },
      ],
      techs:
        "Figma, HTML, CSS, React JS, Node JS, MongoDB, Google cloud platform",
    },
  
    {
      img: require("../../images/success3.png"),
      subTitle: "Invoicing Webapp",
      title: "AP Enterprises",
      para: [
        {
          p: '"A P Enterprises" is a client specific user-friendly web app for efficient invoice management, enabling the admin to generate, edit, and track invoices, while also providing a secure PDF generation and download functionalities.',
        },
        {
          p: "",
        },
      ],
      techs:
        "Figma, HTML, CSS, React JS, Node JS, MongoDB, Google cloud platform",
    },
  
      ];
  